import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { createUrlAlias } from "../../utils/url"
import { withStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid'

import ImageProduct from '../All/ImageProduct'
import ItemMarkParams from './ItemMarkParams'
import ItemMarkCalc from './ItemMarkCalc'
import PropTypes from "prop-types"
import styles from "../../components/styles"

const style = theme => ({
  h1: {
    margin: '0.67em 0 0 0'
  },
  head:{
    marginBottom: '10px',
    '& h1': {
      margin: '0.67em 0 0 0'
    }
  },
  box: {
    marginBottom: '16px'
  },
  ...styles(theme),
})

const ItemMarkTemplate = ({ pageContext: { classMarkData, itemMarkData, groupName }, data, classes }) => {
  let title
  if (classMarkData.mark_type === 1) {
    title = "Кабель " + itemMarkData.name
  }
  if (classMarkData.mark_type === 2) {
    title = "Провод " + itemMarkData.name
  }
  // function getDiam(data) {
  //   let diam = 0
  //   data.item.params.map(function(param) {
  //     if (param.name === "Наружный диаметр") {
  //       diam = parseFloat(param.value.replace(/,/,".").replace(/\s+/,""))
  //     }
  //   })
  //   return diam
  // }
  function getMass(data) {
    let mass = 0
    data.map(function(param) {
      if (param.size_param_name === "Расчетная масса (вес)") {
        mass = parseFloat(param.size_param_value.replace(/,/,".").replace(/\s+/,""))
      }
      return null
    })
    return mass
  }
  return (
    <Layout>
      <SEO title={"⚡" + title + " купить с доставкой, узнать цену, вес, ток, диаметр, характеристики, фото"}
           description={"Купить " + title + " с доставкой, узнать цену, вес, ток, диаметр и характеристики"} />
      <div aria-label="breadcrumb">
        <span><Link to={`/catalog/`}>Продукция</Link></span>
        <span> > </span>
        <span><Link to={`/catalog/${createUrlAlias(groupName)}/`}>{groupName}</Link></span>
        <span> > </span>
        <span><Link to={`/cable/${createUrlAlias(classMarkData.name)}/`}>{classMarkData.name}</Link></span>
        <span> > </span>
        <span>{classMarkData.mark_type === 1 && "Кабель"} {classMarkData.mark_type === 2 && "Провод"} {itemMarkData.name}</span>
      </div>
      <main>
      <div className={classes.head} aria-label="articleHead">
        <h1>{classMarkData.mark_type === 1 && "Кабель"} {classMarkData.mark_type === 2 && "Провод"} {itemMarkData.name}</h1>
        <span dangerouslySetInnerHTML={{ __html: classMarkData.mark_gost }}/>
      </div>
      <Grid container>
        <Grid item lg={2} md={3} xs={12} className={classes.imageBoxGrid}>
          <div className={classes.imageBox}>
            <ImageProduct imageInfo={data.classMarkImage} imageAlt={classMarkData.name}/>
          </div>
        </Grid>
        <Grid item lg={10} md={9} xs={12} className={classes.prodBoxGrid}>
          <Grid container className={classes.box} spacing='2'>
            {/*{classMarkData.mark_const.length > 30 &&*/}
            {/*<Grid item lg={6} md={12}>*/}
            {/*  <div dangerouslySetInnerHTML={{ __html: itemMarkData.size_const }}/>*/}
            {/*</Grid>*/}
            {/*}*/}
            {itemMarkData.size_params.length > 1 &&
            <Grid item lg={6} md={12}>
              <h2>Характеристики {classMarkData.mark_type === 1 && "кабеля"} {classMarkData.mark_type === 2 && "провода"} {itemMarkData.name}</h2>
              <ItemMarkParams data={itemMarkData.size_params}/>
              {getMass(itemMarkData.size_params) !== 0 &&
              <React.Fragment>
                <h2>Расчет веса {classMarkData.mark_type === 1 && "кабеля"} {classMarkData.mark_type === 2 && "провода"} {itemMarkData.name}</h2>
                <ItemMarkCalc cableMass={getMass(itemMarkData.size_params)} cableName={itemMarkData.name}/>
              </React.Fragment>
              }
            </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      </main>
    </Layout>
  )
}

ItemMarkTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ItemMarkTemplate)

export const query = graphql`
    query($image: String!) {
      classMarkImage: file(relativePath: { eq: $image }) {
        childImageSharp {
          fluid(maxWidth: 220, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `
