import React from 'react'
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"
import styles from "../../components/styles"

const style = theme => ({
  ...styles(theme),
  params: {
    fontSize: '0.9em',
    '& dt': {
      width: '55%'
    }
  },
  paramsTable: {
    // maxWidth: '640px'
  }
})


const ItemMarkParams = ({ data, classes }) => {
  return (
    <div className={[classes.AsTable+' '+classes.params]}>
      {data.map(function (size_param, i) {
        return <dl key={i}><dt>{size_param.size_param_name}</dt><dd>{size_param.size_param_value}</dd></dl>
      })}
    </div>
  )
}

ItemMarkParams.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ItemMarkParams)