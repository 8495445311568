import React from 'react'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

class ItemMarkCalc extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      input: "",
      resultWeight: "0 кг."
    }
    this.onChange = this.onChange.bind(this)
  }
  onChange(e){
    const {cableMass} = this.props
    if (e.target.value === '' || e.target.value <= 999999) {
    this.setState({input: e.target.value.toLocaleString(undefined, {maximumFractionDigits:1})})
    this.setState({
      resultWeight: parseFloat(cableMass / 1000 * e.target.value).toLocaleString(undefined, {maximumFractionDigits:1}) + ' кг.'
    })
    }
  }
  render() {
    const {cableMass, cableName} = this.props
    return (
      <React.Fragment>
        <form>
          <label>Введите длину:<br/>
            {/*<input*/}
            {/*  type="number"*/}
            {/*  value={this.state.input}*/}
            {/*  placeholder="0"*/}
            {/*  max="999999"*/}
            {/*  min="0"*/}
            {/*  onChange={this.onChange}*/}
            {/*/>*/}
            <Input
              type="number"
              id="standard-adornment-weight"
              value={this.state.input}
              onChange={this.onChange}
              endAdornment={<InputAdornment position="end">м.</InputAdornment>}
              InputProps={{ inputProps: { min: 0, max: 999999 } }}
              aria-describedby="standard-weight-helper-text"
              style={{width: `150px`}}
              inputProps={{
                'label': 'Введите длину',
              }}
            />
            <strong> — </strong><span style={{fontSize: `1rem`}}>{this.state.resultWeight}</span>
          </label>
          </form>
        Вес 1 метра <strong>{cableName}</strong> — {(cableMass/1000).toLocaleString(undefined, {maximumFractionDigits:1})} кг.
      </React.Fragment>
    )
  }
}

export default ItemMarkCalc